<script setup>
import { useStore } from 'vuex'

// eslint-disable-next-line no-undef
const props = defineProps({
  tooltip: {
    required: true
  },
  styles: {
    required: false,
    default: 'btn-tooltip'
  }
})
// use
const store = useStore()
function togglePopup () {
  store.dispatch('popup/setPopup', {
    status: true,
    tooltip: props.tooltip
  })
}
</script>

<template>
  <button v-if="tooltip && tooltip.image" @click="togglePopup" class="btn-reset" :class="styles" data-bs-toggle="modal" data-bs-target="#tooltipModal">
    <i class="fas fa-info-circle"></i>
  </button>
</template>
